import React from "react";
import IconFacebook from "../svgs/IconFacebook.svg";
import IconInstagram from "../svgs/IconInstagram.svg";

export function SocialIcons() {
  return (
    <div className="social_icons">
      <a
        href="https://www.facebook.com/BounceEventsDurham/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconFacebook />
      </a>
      <a
        href="https://www.instagram.com/bounceeventsdurham"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconInstagram />
      </a>
    </div>
  );
}
